<template>
  <div class='supplier'>
    <div class="action">
      <div>
        <span>单位名称：</span>
        <el-input clearable v-model="keyword" placeholder="请输入" @change="loactionChange" size="small">
        </el-input>
      </div>
      <el-button type="primary" @click="add" size="small">新 建</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%" :header-cell-style="headStyle" :cell-style="rowStyle" size="small">
      <el-table-column prop="name" label="单位名称">
      </el-table-column>
      <el-table-column prop="address" label="详细地址">
      </el-table-column>
      <el-table-column prop="contactPerson" label="联系人">
      </el-table-column>
      <el-table-column prop="contactPhone" label="电话">
      </el-table-column>
      <el-table-column prop="creditCode" label="社会信用统一代码">
      </el-table-column>
      <el-table-column prop="action" label="操作">
        <template slot-scope="{row}">
          <el-button type="text" @click="editSupplier(row.id)">编辑</el-button>
          <el-popover placement="bottom-start" width="180" v-model="row.visible">
            <p>您确认要删除该供应商吗？</p>
            <div style="text-align: right; margin: 10px 0 0 0">
              <el-button size="mini" type="text" @click="row.visible = false">取消</el-button>
              <el-button type="primary" size="mini" @click="ensureDelete(row.id),row.visible = false">确定</el-button>
            </div>
            <el-button type="text" slot="reference" style="color:red">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination :total="total" layout="prev, pager, next" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="getList" />
    <!-- 新增供应商弹框 -->
    <el-dialog :title="title" :visible.sync="addVisible" width="30%" :lock-scroll="false">
      <el-form :model="supplierForm" :rules="rules" ref="supplierForm">
        <el-form-item label="单位名称" prop="name">
          <el-input v-model="supplierForm.name" placeholder="请输入单位名称"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contactPerson">
          <el-input v-model="supplierForm.contactPerson" placeholder="请输入联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input v-model="supplierForm.contactPhone" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="详细地址" prop="address">
          <el-input v-model="supplierForm.address" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="社会信用统一代码">
          <el-input v-model="supplierForm.creditCode" placeholder="请输入社会信用统一代码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitSupplier('supplierForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getSupplierList, addSupplier, deleteSupplier, getSupplierItem, editSupplier } from '@/api'
export default {
  data () {
    return {
      // 表格数据
      tableData: [],
      keyword: '',
      queryParams: {
        pageSize: 10,
        pageNum: 1
      },
      totalList: [],
      total: 0,
      addVisible: false,
      supplierForm: {
        name: '',
        address: '',
        contactPerson: '',
        contactPhone: '',
        creditCode: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入单位名称', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '请输入详细地址', trigger: 'blur' }
        ],
        contactPerson: [
          { required: true, message: '请输入联系人姓名', trigger: 'blur' }
        ],
        contactPhone: [
          { required: true, message: '请输入联系电话', trigger: 'blur' }
        ],
        creditCode: [
          { required: true, message: '请输入社会信用统一代码', trigger: 'blur' }
        ]
      },
      title: '添加供应商'
    };
  },
  created () {
    this.init()
  },
  mounted () {

  },
  computed: {
    ...mapGetters(['cascadedlocation', 'token', 'realname'])
  },
  methods: {
    // 获取供应商列表
    async init () {
      const res = await getSupplierList({ keyword: this.keyword })
      this.totalList = res
      this.total = res.length
      this.getList()
    },
    // 分页操作
    getList () {
      this.tableData = []
      let start = (this.queryParams.pageNum - 1) * this.queryParams.pageSize
      let end = ''
      if (this.queryParams.pageNum * this.queryParams.pageSize > this.total) {
        end = this.total
      }
      else {
        end = this.queryParams.pageSize * this.queryParams.pageNum
      }
      for (let i = start; i < end; i++) {
        this.tableData.push(this.totalList[i])
      }
      // console.log('tableData :>> ', this.tableData);
    },
    // 位置选择
    loactionChange () {
      // console.log('val :>> ', val);
      this.queryParams.pageNum = 1
      this.init()
    },
    // 表格居中
    headStyle () {
      return "text-align:center"
    },
    rowStyle () {
      return "text-align:center"
    },
    // 新增供应商
    add () {
      // this.$refs["supplierForm"].resetFields()
      this.supplierForm = {
        name: '',
        address: '',
        contactPerson: '',
        contactPhone: '',
        creditCode: ''
      }
      this.title = '添加供应商'
      this.addVisible = true
    },
    // 编辑新增
    submitSupplier (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.title === '添加供应商') {
            await addSupplier(this.token, this.supplierForm)
            this.$message.success('新增供应商成功')
            this.$add(
              {
                userName: this.realname,
                module: '供应商管理',
                content: `${this.realname}添加了 ${this.supplierForm.name} 供应商`,
                type: 3,
                platform: 8,
                operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
              }
            )
            this.addVisible = false
            this.loactionChange()
          } else {
            await editSupplier(this.token, this.supplierForm)
            this.$message.success('修改供应商成功')
            this.$add(
              {
                userName: this.realname,
                module: '供应商管理',
                content: `${this.realname}修改了 ${this.supplierForm.name} 供应商`,
                type: 5,
                platform: 8,
                operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
              }
            )
            this.addVisible = false
            this.init()
          }


        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 删除供应商
    async ensureDelete (id) {
      await deleteSupplier({ id })
      this.$message.success('删除供应商成功')
      this.loactionChange()
      this.$add(
        {
          userName: this.realname,
          module: '供应商管理',
          content: `${this.realname}删除了 ${this.supplierForm.name} 供应商`,
          type: 4,
          platform: 8,
          operateTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
        }
      )
    },
    // 编辑供应商
    async editSupplier (id) {
      this.supplierForm = await getSupplierItem({ id })
      this.title = '编辑供应商'
      this.addVisible = true
    }
  }
};
</script>

<style scoped lang="scss">
.supplier {
  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    > div {
      span {
        font-size: 16px;
        margin-left: 10px;
      }
      .el-select,
      .el-input {
        width: 300px;
      }
    }
  }
}
</style>
